import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PostsView from "@/views/PostsView.vue";
import PaymentsView from "@/views/PaymentsView.vue";
import PaymentsSuccessView from "@/views/PaymentsSuccessView.vue";
import ResearcherView from '@/views/ResearcherView.vue';
import Developer from '@/views/DeveloperView.vue'
import Posts from '@/views/PostsView.vue'
import ProjectManager from '@/views/ProjectManagerView.vue'
import Formulator from "@/views/FormulatorView.vue";
import Entrepreneur from "@/views/EntrepreneurView.vue";
/*** TIMEBOX **/
import TimeBoxView from '@/views/apps/TimeBoxView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/es-co',
    name: 'home',
    component: HomeView,
    lang: 'ES'
  },
  {
    path: '/payments',
    name: 'payments',
    component: PaymentsView
  },
  {
    path: '/blog',
    name: 'blog',
    component: Posts
  },
  {
  path: '/researcher',
  name: 'researcher',
  component: ResearcherView
  },
  {
    path: '/developer',
    name: 'developer',
    component: Developer
  },
  {
    path: '/project_manager',
    name: 'project_manager',
    component: ProjectManager
  },
  {
    path: '/formulator',
    name: 'formulator',
    component: Formulator
  },
  {
    path: '/entrepreneur',
    name: 'entrepreneur',
    component: Entrepreneur
  },
  {
    path: '/payments/success',
    name: 'payments_success',
    component: PaymentsSuccessView
  },
  {
    path: '/toolkit/timebox',
    name: 'timebox',
    component: TimeBoxView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
  path: '/blog/:article',
  name: 'PostsView',
  component: PostsView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
